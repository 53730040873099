// frontend/src/AnswerPanel.js

import React, { useState } from 'react';
import axios from './axiosConfig';
import './AnswerPanel.css'

const fillerWords = [
    'i', 'you', 'he', 'she', 'it', 'we', 'they', // personal pronouns
    'me', 'him', 'her', 'us', 'them', // object pronouns
    'my', 'your', 'his', 'her', 'its', 'our', 'their', // possessive pronouns
    'the', 'a', 'an', // articles
    "don't", "doesn't", "ain't",
    'and', 'or', 'but', 'nor', 'for', 'so', 'yet', // conjunctions
    'to', 'at', 'in', 'on', 'of', 'from', 'by', // prepositions
    'with', 'about', 'as', 'if', 'out', 'up', 'down', // common adverbs and prepositions
    'when', 'where', 'why', 'how', 'what' // interrogative words
];

const replaceRandomWords = (sentence, ratio = 0.1, blockedList = fillerWords) => {
    // Split the sentence into an array of words
    // let words = sentence.match(/(?:\w[\w'-]*|[,.;:!?])/g) || [];
    let words = sentence.split(/\s+/)
    console.log('--words', JSON.stringify(words))
    // let words = sentence.match(/(?:[\w'-]+|[.,!?;:])/g) || [];

    // Calculate the number of words to replace
    const n = Math.ceil(words.length * ratio);

    // Get indices of words that are not in the blocked list
    let replaceableIndices = [];
    for (let i = 0; i < words.length; i++) {
        if (!blockedList.includes(words[i].toLowerCase())) {
            replaceableIndices.push(i);
        }
    }

    // Ensure n does not exceed replaceable indices
    const actualN = Math.min(n, replaceableIndices.length);

    // Shuffle replaceable indices (Fisher-Yates shuffle)
    for (let i = replaceableIndices.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [replaceableIndices[i], replaceableIndices[j]] = [replaceableIndices[j], replaceableIndices[i]];
    }

    // Replace words at selected indices with ___
    for (let i = 0; i < actualN; i++) {
        words[replaceableIndices[i]] = '___';
    }

    // Join the array back into a sentence
    let replacedSentence = words.join(' ');
    return replacedSentence;
}

const AnswerPanel = ({ answers, onSelectAnswer, onNextMaskedAnswer, onDoneAnswer }) => {
    const [selectedAnswer, setSelectedAnswer] = useState('')
    const [maskedAnswer, setMaskedAnswer] = useState('')
    const handleSelectAnswer = async (answer) => {

        setSelectedAnswer(answer)
        const nextMaskedAnswer = replaceRandomWords(answer)
        // const response = await axios.post('/api/english_speak/generate_masked_answer/', { answer })
        // console.log('-- generate masked answer response', response.data)
        // setMaskedAnswer(response.data.content
        setMaskedAnswer(nextMaskedAnswer)
        onSelectAnswer(answer)
    }
    const handleNextMaskedAnswer = async () => {
        const nextMaskedAnswer = replaceRandomWords(selectedAnswer)
        // const response = await axios.post('/api/english_speak/generate_masked_answer/', { answer: selectedAnswer })
        // console.log('-- generate masked answer response', response.data)
        setMaskedAnswer(nextMaskedAnswer)
        onNextMaskedAnswer(nextMaskedAnswer)
    }
    const handleDoneMaskedAnswer = async () => {
        onDoneAnswer(selectedAnswer)
    }
    return (
        <div>
            <h2 className='text-center'>Answers:</h2>
            <ul className='list-group'>
                {answers.map((answer, index) => (
                    <li className='list-group-item d-flex justify-content-between align-items-center' key={index}>
                        <div className="text-left">
                            {answer == selectedAnswer ?
                                <div className='blurred-content'>
                                    {answer}
                                </div> :
                                answer
                            }
                        </div>
                        <button className='btn btn-secondary' onClick={() => handleSelectAnswer(answer)}>Select</button>
                    </li>
                ))}
            </ul>
            {maskedAnswer ?
                <div>
                    <div className='text-center masked-answer-actions'>
                        <button className='btn btn-primary center' onClick={() => handleNextMaskedAnswer()}>Next</button>
                        <button className='btn btn-secondary center' onClick={() => handleDoneMaskedAnswer()}>Done</button>
                    </div>
                    <div className='border rounded masked-answer-container'>
                        <blockquote className='blockquote text-center'>
                            <p class='mb-0'>{maskedAnswer}</p>
                        </blockquote>
                    </div>
                </div> : <div></div>
            }
        </div>
    );
};

export default AnswerPanel;
