import React, { useEffect, useState } from 'react';
import { AuthProvider } from './AuthProvider';
import UserProfileForm from './UserProfileForm';
import axios from './axiosConfig';
import NavigationMenu from './NavigationMenu';
import QuestionForm from './TeacherQuestionForm';
import AnswerPanel from './AnswerPanel';
import { useLocation } from 'react-router-dom';
import './ClassRoomPage.css'
import { BACKEND_HOST_NAME } from './constants';
import Button from 'react-bootstrap/Button';

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const ClassRoomPage = () => {
    const [answers, setAnswers] = useState([])
    const [question, setQuestion] = useState('')
    const [selectedAnswer, setSelectedAnswer] = useState('')
    const { state } = useLocation();
    let classroom
    let userProfile
    if (state) {
        classroom = state.classroom
        userProfile = state.userProfile
        localStorage.setItem('classroom', classroom)
        localStorage.setItem('userProfile', userProfile)
    } else {
        classroom = localStorage.getItem('classroom')
        userProfile = localStorage.getItem('userProfile')
    }
    const name = userProfile.name
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('')
    const userId = localStorage.getItem('userId')
    const socket = new WebSocket(`wss://${BACKEND_HOST_NAME}/ws/classroom/${classroom.code}/`);
    console.log('--classroom', classroom)
    console.log('--userProfile', userProfile)
    const isTeacher = classroom.teacher == userProfile.id
    const actor = isTeacher ? 'teacher' : 'student';
    const handleReceveAnswers = (receivedAnswers) => {
        setAnswers(receivedAnswers)
        console.log('-- teacher sent answers', receivedAnswers)
        sendMessage({ actor, action: 'setAnswers', payload: { answers: receivedAnswers } })
    }
    const onStudentSelectAnswer = (answer) => {
        setSelectedAnswer(answer)
        sendMessage({
            actor: 'student',
            payload: { answer },
            action: 'setAnswer'
        })
    }
    const onStudentNextMaskAnswer = (maskedAnswer) => {
        sendMessage({
            actor: 'student',
            action: 'nextMaskedAnswer',
            payload: { answer: maskedAnswer }
        })
    }

    const onStudentDoneAnswer = (answer) => {
        sendMessage(
            {
                actor: 'student',
                action: 'doneAnswer'
            }
        )
    }

    const onTeacherSubmitQuestion = (question) => {
        sendMessage({
            actor: 'teacher',
            action: 'setQuestion',
            payload: {
                question
            }
        })
    }

    useEffect(async () => {
        socket.onopen = () => {
            console.log('WebSocket connected');
        };
        socket.onmessage = (event) => {
            console.log('-- fuck event', event)
            const receivedData = JSON.parse(event.data).payload;
            console.log('-- fuck received data', receivedData)
            const receivedActor = receivedData.actor
            const receivedAction = receivedData.action
            // setMessages((prevMessages) => [...prevMessages, message]);
            // teacher sets question and get answers
            if (receivedActor == 'teacher') {
                if (receivedAction == 'setAnswers') {
                    const receivedAnswers = receivedData.payload.answers
                    // console.log('-- fuck answers', receivedAnswers)
                    setAnswers(receivedAnswers)
                    localStorage.setItem('answers', JSON.stringify(receivedAnswers))
                } else if (receivedAction == 'setQuestion') {
                    setQuestion(receivedData.payload.question)
                    localStorage.setItem('question', receivedData.payload.question)
                }
            } else if (actor != 'student' && receivedActor == 'student') {
                //student select answer, also display on teacher's screen
                setMessages((prevMessages) => [...prevMessages, receivedData])
            }
        };
        if (localStorage.getItem('answers')) {
            try {
                console.log('-- local storage answers', localStorage.getItem('answers'))
                setAnswers(JSON.parse(localStorage.getItem('answers')))
            } catch (e) {
                console.log('-- clear up answers because it is not valid')
                localStorage.setItem('answers', null)
                setAnswers([])
            }
        }
        return () => {
            socket.close();
        };

    }, [])
    const sendMessage = (payload) => {
        console.log('--sending payload', payload)
        let failed = false
        let timeout = 5
        while (failed && timeout > 0) {
            try {
                socket.send(JSON.stringify(payload));
                break
            } catch (e) {
                failed = true
                timeout -= 1
                sleep(1000)
            }
        }
    };
    const onInputChange = (e) => {
        setMessage(e.target.value)
    }
    return (
        <AuthProvider>
            <div><NavigationMenu /></div>
            <h1>{classroom.code}</h1>
            <h2>Hi {isTeacher ? 'Teacher ' : ''} {name}</h2>
            {isTeacher ?
                <QuestionForm
                    onReceivedAnswers={handleReceveAnswers}
                    onSubmitQuestion={onTeacherSubmitQuestion} />
                : <div></div>}
            <h2 className='text-center'>Question</h2>
            <div className='border rounded masked-answer-container'>
                <blockquote className='blockquote text-center'>
                    <p className='mb-0'>{question}</p>
                </blockquote>
            </div>
            <AnswerPanel
                answers={answers}
                onSelectAnswer={onStudentSelectAnswer}
                onNextMaskedAnswer={onStudentNextMaskAnswer}
                onDoneAnswer={onStudentDoneAnswer} />
            {isTeacher ?
                <ul>
                    {messages.map((msg, index) => (
                        <li key={index}>{JSON.stringify(msg)}</li>
                    ))}
                </ul> : <div></div>
            }
            {/* <input type="text" onChange={onInputChange} /> */}
            {/* <Button variant='primary' onClick={() => sendMessage({
                message
            })}>Send</Button> */}
        </AuthProvider>
    )

}

export default ClassRoomPage
