import React, { useState } from 'react';
import axios from './axiosConfig';
import Button from 'react-bootstrap/Button';
import { BACKEND_HOST } from './constants';

const QuestionForm = ({ onReceivedAnswers, onSubmitQuestion }) => {
    // State to hold the user's question
    const [question, setQuestion] = useState('');

    // Handler function for when the user submits the form
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        // You can add your logic here to handle the submitted question
        console.log('Submitted question:', question);
        // Clear the input field after submission (optional)
        setQuestion('');
        const response = await axios.post(`${BACKEND_HOST}/api/english_speak/answer_question/`, { question })
        onReceivedAnswers(response.data.answers)
        console.log('-- question response', response)
        onSubmitQuestion(question)
    };

    // Handler function to update the question state as the user types
    const handleChange = (event) => {
        const question = event.target.value;
        setQuestion(question);
    };

    return (
        <form onSubmit={handleSubmit} className='form-inline'>
            <div className='form-group mx-sm-3 mb-2'>
                <label className='sr-only' htmlFor='teacherQuestion'>
                    Enter your question:
                </label>
                <input
                    id='teacherQuestion'
                    className='form-control'
                    type="text"
                    value={question}
                    onChange={handleChange}
                    placeholder="Type your question here"
                    required // Makes the input field mandatory
                />
                <button className='btn btn-primary' type="submit">Submit</button>
            </div>
        </form>
    );
};

export default QuestionForm;
