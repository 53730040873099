import React, { useState } from 'react';
import axios from './axiosConfig';
import { useAuth } from './AuthProvider';
import { BACKEND_HOST } from './constants';

const UserProfileForm = () => {
    const { isAuthenticated } = useAuth();
    const [formData, setFormData] = useState({
        name: '',
        birthday: '',
        profession: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BACKEND_HOST}/api/english_speak/user_profile/`, formData);
            console.log('Success:', response.data);
            alert('Profile saved!');
        } catch (error) {
            console.error('Error:', error.response.data);
            alert('Failed to save profile.');
        }
    };

    if (isAuthenticated) {

        return (
            <div>
                <h1>User Profile Form</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Name"
                        required
                    />
                    <input
                        type="date"
                        name="birthday"
                        value={formData.birthday}
                        onChange={handleChange}
                        placeholder="Birthday"
                        required
                    />
                    <input
                        type="text"
                        name="profession"
                        value={formData.profession}
                        onChange={handleChange}
                        placeholder="Profession"
                        required
                    />
                    <button type="submit">Submit</button>
                </form>
            </div>
        );
    } else {
        return (
            <div>Unauthenticated!</div>
        )
    }
}

export default UserProfileForm;
