import './App.css';
import React, { useEffect, useState } from 'react';
import { AuthProvider } from './AuthProvider';
import UserProfileForm from './UserProfileForm';
import axios from './axiosConfig';
import NavigationMenu from './NavigationMenu';
import { BACKEND_HOST } from './constants';

function UserProfilePage() {
    const [isUserProfileValid, setUserProfileValid] = useState(false);
    const [userProfile, setUserProfile] = useState({})

    useEffect(async () => {
        try {
            const res = await axios.get(`${BACKEND_HOST}/api/english_speak/user_profile/`)
            const userProfile = res.data;
            console.log('--userProfile', userProfile)
            setUserProfileValid(true)
            setUserProfile(userProfile)
        } catch (error) {
            console.log('-- failed to get user profile', error)
            setUserProfileValid(false)
        }
    }, [])
    return (
        <AuthProvider>
            <div><NavigationMenu /></div>
            {isUserProfileValid ? <div>
                UserProfile:
                <h2>Name: {userProfile.name}</h2>
                <h3>Profession: {userProfile.profession}</h3>
                <h3>DOB       : {userProfile.birthday}</h3>
            </div> : <UserProfileForm />}
        </AuthProvider>
    )
}

export default UserProfilePage;
