import React from 'react';
import { Link } from 'react-router-dom';

const NavigationMenu = () => {
    return (
        <nav className='navbar navbar-expand-lg'>
            <div className='container'>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link to="/" className="nav-link">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/profile" className="nav-link">Profile</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/classroom" className="nav-link">Classroom</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/join_classroom" className="nav-link">Join Classroom</Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default NavigationMenu;
