import { GoogleLogin } from 'react-google-login';
import React from 'react';
import { useAuth } from './AuthProvider';
import { BACKEND_PROXY } from './constants';

function Login() {
    const { login, isAuthenticated } = useAuth();
    const responseGoogle = async (response) => {
        const res = await fetch(`${BACKEND_PROXY}/api/auth/google/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: response.tokenId })
        });
        console.log('response', res)
        const data = await res.json()
        console.log('-- response data', data)
        const token = data['access']
        if (!token) {
            console.log('error: invalid token')
            return
        }
        console.log('--token', token)
        localStorage.setItem('accessToken', token)
        localStorage.setItem('refreshToken', data['refresh'])
        login(token)
        // Here you would usually send the token to the backend for verification
    };
    const onFailure = (res) => {
        console.log('Login failed:', res);
    };
    return (
        <div>
            {isAuthenticated ? <div>Login Page: Authenticated.</div> :
                <div>
                    <GoogleLogin
                        clientId="769541067359-rrk90pg3nifq2258oh58a4mvm1oule7n.apps.googleusercontent.com"
                        buttonText="Login with Google"
                        onSuccess={responseGoogle}
                        onFailure={onFailure}
                        cookiePolicy={'single_host_origin'}
                    />
                </div>
            }

        </div>
    );
}

export default Login;
