import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import UserProfilePage from './UserProfilePage';
import ClassRoomPage from './ClassRoomPage';
import JoinClassroom from './ClassroomStagingPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "/profile",
    element: <UserProfilePage />
  },
  {
    path: '/classroom',
    element: <ClassRoomPage />
  },
  {
    path: '/join_classroom',
    element: <JoinClassroom />
  }
])

ReactDOM.render(
  <React.StrictMode>
    <div className='container'>
      <RouterProvider router={router} />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);
