import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';  // Ensure axios is available
import { jwtDecode } from 'jwt-decode';
import { BACKEND_HOST } from './constants';

function isTokenExpired(token) {
    try {
        const decoded = jwtDecode(token);
        const currentDate = new Date();
        // JWT exp is in seconds
        const expirationDate = new Date(decoded.exp * 1000);
        return expirationDate < currentDate;
    } catch (error) {
        console.error("Failed to decode JWT:", error);
        return true; // Assume the token is invalid/expired on error
    }
}

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        const tokenExpired = isTokenExpired(token)
        console.log('--isTokenExpired', tokenExpired);
        if (tokenExpired) {
            refreshAccessToken()
        }
        setIsAuthenticated(!!token && !tokenExpired);
    }, []);

    const login = (token) => {
        localStorage.setItem('accessToken', token);
        setIsAuthenticated(true);
    };

    const logout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setIsAuthenticated(false);
    };

    const refreshAccessToken = async () => {
        console.log('-- refresh access token')
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            logout();
            return;
        }
        try {
            const response = await axios.post(`${BACKEND_HOST}/api/token/refresh/`, {
                refresh: refreshToken
            });
            localStorage.setItem('accessToken', response.data.access);
            setIsAuthenticated(true);
        } catch (error) {
            console.error("Token refresh failed:", error);
            logout();
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
