
import React from 'react';
import { useAuth } from './AuthProvider';

// Dashboard Component
const Dashboard = () => {
    const { isAuthenticated, logout } = useAuth()
    return (
        <div>
            {isAuthenticated ?
                <div>
                    <h2>Dashboard</h2>
                    <button onClick={logout}>Log Out</button>
                </div> : <div>Dashboard: Unauthenticated.</div>
            }
        </div>
    );
};

export default Dashboard;
