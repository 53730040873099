import axios from 'axios';
import { BACKEND_HOST } from './constants';

// Create an instance of axios
const axiosInstance = axios.create({
    baseURL: BACKEND_HOST, // Adjust this to match your API server
});

// Set up request interceptor to attach the JWT token to every request
axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

// Set up response interceptor to handle token refresh on 401 errors
axiosInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');
            try {
                const res = await axios.post(`${BACKEND_HOST}/api/token/refresh/`, {
                    refresh: refreshToken
                });
                if (res.status === 200) {
                    localStorage.setItem('accessToken', res.data.access);
                    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access;
                    originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access;
                    return axios(originalRequest);
                }
            } catch (refreshError) {
                console.error('Unable to refresh token:', refreshError);
                // Handle cases where refresh fails, e.g., redirect to login
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
