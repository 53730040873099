import './App.css';
import React from 'react';
import Login from './GoogleLogin';
import { AuthProvider, useAuth } from './AuthProvider';
import Dashboard from './Dashboard';
import NavigationMenu from './NavigationMenu';

function App() {
  return (
    <AuthProvider>
      <div className='container'>
        <div>
          <NavigationMenu />
        </div>
        <div>
          <Dashboard />
          <Login />
        </div>
      </div>
    </AuthProvider>
  )
}

export default App;
